import React from 'react'

import BackgroundImage from 'gatsby-background-image'

import { graphql, navigate, useStaticQuery } from 'gatsby'
import { WelcomeFarmaceuticoQuery } from '../generated/graphql'
import { useCookies } from 'react-cookie'
import WelcomeHeader from './WelcomeHeader'
import WelcomeBody from './WelcomeBody'
import WelcomeGrid from './WelcomeGrid'
import WelcomeFooter from './WelcomeFooter'
import styled from '@emotion/styled'

const query = graphql`
	query WelcomeFarmaceutico {
		file(relativePath: { eq: "welcome-farmaceutico.jpg" }) {
			childImageSharp {
				fluid(quality: 100, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`

const StyledGBI = styled(BackgroundImage)`
	width: 100vw;
	height: 100%;
	max-width: 100%;
`

const welcomeFarmaceutico = () => {
	const data: WelcomeFarmaceuticoQuery = useStaticQuery(query)
	const [cookies, setCookie, removeCookie] = useCookies()

	const onSubmit = (values: { dontShow: boolean }) => {
		if (values.dontShow) {
			setCookie('medico-welcome', true, {
				maxAge: 31536000 // 1 year
			})
		}
		navigate('/assinatura?tipo=farmaceutico')
	}

	return (
		<WelcomeGrid>
			<WelcomeHeader />
			<StyledGBI Tag='section' fluid={data.file?.childImageSharp?.fluid}>
				<WelcomeBody
					onSubmit={onSubmit}
					title='Farmacêuticos, façam a gestão de prescrições eletrônicas aqui'
					text={
						<>
							Gerencie, consulte a validade e assine o receituário dos medicamentos para dispensação com o VIDaaS:
							Certificado Digital em Nuvem Valid com rapidez e segurança.
							<br />
							<br />
							Você poderá: gerenciar, assinar e armazenar os receituários recebidos de receita simples, especiais,
							antimicrobianas e atestados individuais tudo de acordo com as normas do Conselho Federal de Farmácia e
							ANVISA. A praticidade que você precisa em um só lugar.
						</>
					}
				/>
			</StyledGBI>
			<WelcomeFooter />
		</WelcomeGrid>
	)
}

export default welcomeFarmaceutico
