import React from 'react'
import WelcomeFarmaceutico from '../Welcome/WelcomeFarmaceutico'
import Layout from '../components/layout'
import SEO from '../components/seo'

const WelcomeFarmaceuticoPage = () => {
	return (
		<Layout>
			<SEO keywords={[`valid`, `receita`, `médica`, `farmacêutica`, `farmácia`]} title='Boas vindas' />
			<WelcomeFarmaceutico />
		</Layout>
	)
}

export default WelcomeFarmaceuticoPage
